#navContainer {
  color: #0985a4;
  display: flex;
  height: 80vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1em 1em 1em 2em;
  margin-bottom: 100px;
}

.togglerIcon {
  font-size: 2rem;
  padding: 0.5rem;
  margin: -1rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: color 0.75s ease;
  background-color: var(--color-neutral);
  border-radius: 30%;
}

#menuBadge {
  margin-left: 5px;
  padding: 5px 5px;
  width: fit-content;
}

.dashboardSpinner {
  margin-left: 0.5rem;
}

.reportSpinner {
  margin-left: 0.5rem;
}

.mb-3-reports {
  margin-bottom: 1rem;
  padding: 0;
  width: 100%;
}

.reportSearchBtn {
  color: blue;
  border: none;
}

.navDropMenu {
  width: 100%;
  height: auto;
  background-color: var(--color-neutral);
  border: none;
  border-radius: 0;
  padding: 2px 0;
  margin: 0;
}

#navContainer .nav-ul li.unpublished a {
  color: #ffc107 !important;
  opacity: 0.8;
  font-style: italic;
}

#navContainer > div.togglerIcon {
  height: 40px;
  position: absolute;
  right: -2rem;
  width: 35px;
  z-index: 10;
}

#navContainer > div.togglerIcon > svg {
  color: #fff;
}

#navContainer .nav-ul li a,
#navContainer
  .nav-ul
  label:not(li.selected > label, li.selected > a label, li > a.active) {
  color: #0985a4 !important;
}

#navContainer .nav-ul li a,
#navContainer .nav-ul label {
  font-size: 0.95em;
  padding: 3px 0;
  text-decoration: none;
}

#navContainer li label {
  padding-left: 10px;
}

#navContainer li svg {
  width: 20px;
  margin-right: 8px;
}

#navContainer li.selected svg {
  color: #5e5e5e;
}

#navContainer .nav-ul,
#navContainer .navDropMenu {
  list-style: none;
}

#navContainer .nav-ul {
  padding: 0;
}

#navContainer .nav-ul li {
  margin: 0.5em 0 0;
}

#navContainer .nav-ul li:hover,
#navContainer .nav-ul li:visited {
  background-color: white;
}

#navContainer .navDropMenu {
  background-color: transparent;
  padding: 0 10px;
}

#navContainer .navDropMenu.hide {
  display: none;
}

#navContainer .navDropMenu.expanded {
  box-shadow: 1px 1px 2px lightgrey;
  display: block;
  background-color: #fff;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  position: absolute;
  border: 1px solid lightgrey;
  border-radius: 8px;
  z-index: 10;
  top: 0em;
  left: calc(100% - 24px);
  height: 80vh;
  overflow: scroll;
}

.navDropMenu.expanded ul li a {
  font-size: 14px;
  padding: 10px 0 !important;
}

#navContainer > ul > li > ul > li {
  margin: 15px 10px !important;
}

li.selected > label,
li.selected > a label {
  color: #5e5e5e !important;
}

.remove {
  display: none !important;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-menu-item,
.disabled {
  opacity: 0.5;
}

.disabled-menu-item > div,
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  #navContainer {
    height: 100vh;
    padding: 1em;
  }

  .togglerIcon {
    font-size: 1.5rem;
    margin: -0.5rem 0.5rem 0.5rem 0.5rem;
  }

  #navContainer .nav-ul li a,
  #navContainer .nav-ul label {
    font-size: 0.9em;
    padding: 4px 0;
  }

  #navContainer li label {
    padding-left: 5px;
  }

  #navContainer li svg {
    width: 18px;
    margin-right: 6px;
  }

  #navContainer .navDropMenu.expanded {
    top: 2em;
    left: calc(100% - 20px);
    height: 80vh;
  }

  .navDropMenu.expanded ul li a {
    font-size: 12px;
    padding: 8px 0 !important;
  }
}

@media (max-width: 576px) {
  #navContainer {
    padding: 0.5em;
  }

  .togglerIcon {
    font-size: 1.2rem;
    margin: -0.5rem 0.2rem 0.2rem 0.2rem;
  }

  #navContainer .nav-ul li a,
  #navContainer .nav-ul label {
    font-size: 0.8em;
    padding: 3px 0;
  }

  #navContainer li label {
    padding-left: 3px;
  }

  #navContainer li svg {
    width: 16px;
    margin-right: 4px;
  }

  #navContainer .navDropMenu.expanded {
    top: 1.5em;
    left: calc(100% - 16px);
    height: 70vh;
  }

  .navDropMenu.expanded ul li a {
    font-size: 10px;
    padding: 6px 0 !important;
  }
}
