.login-container {
  width: 500px !important;
  margin: 0 auto;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #47d2f5;
  margin-top: 120px;
  height: 600px;
  padding: 0 !important;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.input-container {
  margin-bottom: 15px;
}

label {
  font-weight: 500;
  color: black
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 40px;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: black;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
}

.login-logo {
  width: 50%;
  height: 100px;
  margin: 2em 25% 4em;
  padding: 5px;
}

.loginBtn {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  height: 50px;
  color: white;
  font-size: 1.2em;
  background-color: #1a82c5;
  letter-spacing: 1px;
  font-weight: 400;
}
.login-container .loginBtn:hover, .login-container .btn-primary:hover {
  background-color: #1a82c5; 
}

.report-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px;
  font-size: 1.5rem;
}

.login-container form {
  background-color: #fafafa;
  padding: 3em 2em;
  height: 402px;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .login-container {
    width: 100%;
    margin-top: 60px;
  }
}

/* Styles for very small devices */
@media (max-width: 480px) {
  .login-container {
    width: 100%;
    margin-top: 60px;
  }

  .login-logo {
    width: 80%;
    margin: 1em 10% 2em;
  }

  .login-container form {
    padding: 1.5em 1em;
  }

  .loginBtn {
    height: 40px;
    font-size: 0.9em;
  }

  .report-spinner-container {
    font-size: 1rem;
  }
}