/* MASTER */
.load-container,
.welcome-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  min-height: 55vh !important;
  position: relative;
}

.load-container {
  background-color: #e0d9d9 !important;
  border: 1px solid #c4c3c3 !important;
  padding-bottom: 5px;
  width: 97% !important;
  right: 20px;
}

.alert-container,
.table {
  margin-top: 20px;
  width: 100%;
}

.modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modal-body p {
  text-align: center;
}

.syncspinner,
.menuLoading {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  color: var(--color-highlight);
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 20px;
  padding: 15px;
  width: 30px;
}

.disclaimer,
.syncErrorMessage,
.syncSuccessMessage {
  color: #6c6c6c;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px;
}

.syncTitle {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  margin: 20px;
}

.icon-success,
.icon-error {
  font-size: 9rem;
}

.icon-success {
  color: green;
}

.icon-error {
  color: red;
}

.welcome-screen {
  background-color: var(--color-neutral) !important;
  margin-bottom: 20px;
  width: 90%;
}

.welcome-screen-logo {
  height: 100%;
  margin: 0 auto;
  max-height: 600px;
  max-width: 600px;
  padding: 10px;
  width: 100%;
}

.welcome-screen h3 {
  font-size: 2rem;
  font-weight: 900;
}

.welcome-screen-title {
  color: var(--color-primary);
  font-size: 1.25rem;
  font-weight: bold;
  margin: 20px;
  padding: 10px;
  text-align: center;
}

.reportLoadingSpinner {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  color: var(--color-highlight);
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 20px;
  padding: 15px;
  width: 30px;
}

/* Uncomment when skip sync is implemeneted */
/* .load-container,
.welcome-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  min-height: 55vh !important;
  position: relative;
}

.load-container {
  background-color: #e0d9d9 !important;
  border: 1px solid #c4c3c3 !important;
  padding-bottom: 5px;
  width: 97% !important;
  right: 20px;
}

.alert-container,
.table {
  margin-top: 20px;
  width: 100%;
}

.modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modal-body p {
  text-align: center;
}
.syncspinner,
.menuLoading {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  color: var(--color-highlight);
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 20px;
  padding: 15px;
  width: 30px;
}

.disclaimer,
.syncErrorMessage,
.syncSuccessMessage,
.syncSkippedMessage {
  color: #6c6c6c;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 20px;
}

.syncTitle {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  margin: 20px;
}

.icon-success,
.icon-error,
.icon-sync {
  font-size: 9rem;
}

.icon-success {
  color: green;
}

.icon-error {
  color: red;
}

.icon-sync {
  color: blue;
}

.welcome-screen {
  background-color: var(--color-neutral) !important;
  margin-bottom: 20px;
  width: 90%;
}

.welcome-screen-logo {
  height: 100%;
  margin: 0 auto;
  max-height: 600px;
  max-width: 600px;
  padding: 10px;
  width: 100%;
}

.welcome-screen h3 {
  font-size: 2rem;
  font-weight: 900;
}

.welcome-screen-title {
  color: var(--color-primary);
  font-size: 1.25rem;
  font-weight: bold;
  margin: 20px;
  padding: 10px;
  text-align: center;
}

.reportLoadingSpinner {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  color: var(--color-highlight);
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 20px;
  padding: 15px;
  width: 30px;
} */
