.import-data-container {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .table-wrapper {
    margin-top: 15px;
    overflow-x: auto;
    background-color: #fff;
  }
  
  .alert-danger {
    margin-top: 20px;
  }
  