:root {
  --color-primary: #fff;
  --font-size-regular: 1.0rem;
  --font-size-large: 1.5rem;
}

.welcome-message {
  font-size: var(--font-size-large);
  color: var(--color-primary);
  padding: 0 20px;
}

.links {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.links a,
.blur-toggle,
.fa-lock,
.nav-dropdown .dropdown-toggle {
  padding: 2px 8px;
  color: var(--color-primary);
  font-size: var(--font-size-regular);
  background: none;
  border: none;
  cursor: pointer;
}

.links a:hover,
.blur-toggle:hover,
.fa-lock:hover,
.nav-dropdown .dropdown-toggle:hover {
  color: rgb(104, 101, 101);
}

.nav-dropdown .dropdown-menu {
  background-color: #f8f9fa;
}

.nav-dropdown .dropdown-item {
  color: #000;
  padding: 10px 20px;
}

.nav-dropdown .dropdown-toggle::after {
  display: none;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .links a,
  .blur-toggle {
    padding: 0 8px;
  }
  .welcome-message {
    font-size: var(--font-size-regular);
  }
}
