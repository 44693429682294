/* src/components/Maintenance.css */
.modal-fullscreen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .maintenance-body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    background-color: #00acd7; 
    color: #ffffff; 
  }
  
  .maintenance-content {
    max-width: 600px;
    margin: auto;
  }
  
  .maintenance-content h1 {
    font-size: 3.5em;
    margin-bottom: 0.5em;
    color: yellow;
  }
  
  .maintenance-content p {
    font-size: 1.5em;
  }
  
  .maintenance-content button {
    margin-top: 1em;
  }
  