.chat-container {
  background-color: var(--color-secondary);
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
}

.chat-title {
  font-size: 24px;
  /* color: var(--color-neutral); */
  margin-bottom: 15px;
}

.chat-welcome {
  font-size: 18px;
  /* color: var(--color-neutral); */
  margin-bottom: 10px;
}

.chat-form {
  display: flex;
  gap: 10px;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--color-tertiary);
  border-radius: 5px;
  transition: border-color 0.3s;
}

.chat-input:focus {
  border-color: var(--color-neutral);
  outline: none;
}

.chat-submit-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-neutral);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-submit-button:hover {
  background-color: var(--color-highlight);
}

.chat-clear-button {
  border: none;
  border-radius: 5px;
  background-color: var(--color-tertiary);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.chat-clear-button:hover {
  background-color: rgba(255, 68, 0, 0.681);
  color: black;
}

.chat-response {
  margin-top: 15px;
  padding: 10px;
  background-color: var(--color-primary);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
