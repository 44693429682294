/* Define root variables for consistent color usage */
:root {
  --primary-color: #007bff;
  --hover-primary-color: #0056b3;
  --secondary-color: #28a745;
  --hover-secondary-color: #218838;
  --tertiary-color: #ffc107;
  --hover-tertiary-color: #e0a800;
  --background-color: whitesmoke;
  --text-color: black;
  --button-text-color: white;
  --border-color: #ddd;
  --header-bg-color: #f3f4f6;
}

/* Styles for the main dashboard container */
.dashboard-container {
  padding: 10px;
  margin: 5px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  height: auto;
  margin-bottom: 100px;
}

/* Styles for individual report cards */
.report-card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 5px;
  margin: 5px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 400px;
  height: auto;
}

/* Hover effect for report cards */
.report-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Styles for report titles */
.report-title {
  font-size: 23px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 10px;
  padding: 5px;
  line-height: 1.4;
  width: 100%;
}

/* Styles for action buttons */
.action-btn,
.print-btn,
.download-btn,
.more-info-btn {
  background-color: var(--primary-color);
  color: var(--button-text-color);
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

/* Hover effect for action buttons */
.action-btn:hover,
.print-btn:hover,
.download-btn:hover,
.more-info-btn:hover {
  background-color: var(--hover-primary-color);
}

/* Focus effect for action buttons */
.action-btn:focus,
.print-btn:focus,
.download-btn:focus,
.more-info-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Icon alignment in download button */
.download-btn .fa-download {
  margin-right: 5px;
}

/* Styles for dashboard table */
.dashboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  bottom: 5px;
  flex-grow: 100;
}

/* Styles for table header */
.dashboard-table thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.dashboard-table thead th {
  position: sticky;
  top: 0;
  background: white;
}

/* Styles for table body */
.dashboard-table tbody {
  display: block;
  max-height: unset;
}

.dashboard-table th,
.dashboard-table td {
  width: auto;
  padding: 0.5em;
}

/* Styles for dashboard header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: var(--header-bg-color);
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

/* Styles for dashboard title */
.dashboard-title {
  margin: 0 auto;
  font-size: 32px;
  font-weight: 600;
}

.dashboard-dates {
  margin: 10px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 8px;
  border-radius: 10px;
}

/* Styles for action buttons within the dashboard */
.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border: none;
}

.dashboard-actions .action-btn {
  margin-left: 5px;
  width: fit-content;
}

.dashboard-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

/* Styles for report actions */
.report-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

/* Styles for print button */
.print-btn {
  background-color: var(--secondary-color);
}

.print-btn:hover {
  background-color: var(--hover-secondary-color);
}

/* Styles for more info button */
.more-info-btn {
  background-color: var(--tertiary-color);
  color: var(--text-color);
}

.more-info-btn:hover {
  background-color: var(--hover-tertiary-color);
}

/* Media query for small screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .report-card {
    padding: 10px;
    margin: 5px;
  }

  .report-title {
    font-size: 20px;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-title {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .dashboard-actions .action-btn {
    margin-bottom: 5px;
    width: 100%;
  }

  .dashboard-table {
    padding: 3px;
  }

  .dashboard-dates {
    margin-bottom: 10px;
  }
}
