.ReportListContainer {
  margin-top: 10px;
  background-color: #f5f5f5;
  border: 2px solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  width: 100%;
}

.allReportContainer {
  width: 100%;
  padding: 10px;
  margin: 0 auto;
}

.reportListHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  outline: 2px solid #e7e7e7;
  border-radius: 5px;
  margin-bottom: 20px;
}

.reportListHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reportListHeaderTitle {
  font-size: 34px;
  font-weight: bold;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 10px;
}

.inputGroupContainer {
  flex: 1;
  max-width: 80%;
  margin: 0 auto;
}

.allReportContainer .menuItemBtn {
  background-color: var(--color-neutral) !important;
  color: var(--color-primary) !important;
  border: none !important;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.allReportContainer .menuItemBtn:hover {
  background-color: var(--color-tertiary) !important;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

.filterModalTitles {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: underline;
}

.notesText {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.reportGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 95%;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
}

.expandedGrid {
  grid-template-columns: 1fr;
}

.reportCard {
  transition: transform 0.3s ease-in-out;
}

.reportCard.expanded {
  grid-column: span 1;
  width: 100%;
}