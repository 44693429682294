/* Form Controls */
.form-control {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  transition: border-color 0.2s;
}

.card-title-header {
  font-size: 1.5rem;
  font-weight: 600;
}

.jumbotron {
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

/* Active Row Highlighting */
.list-group-item.selected-report,
.list-group-itemDash.selected-report {
  background-color: #007bff;
  color: white;
}

.form-check-input {
  display: none;
}

.card {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  border-radius: 8px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.5rem;
}

.card-header-actions {
  display: flex;
  align-items: center;
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  justify-content: space-between;
}

.action-title {
  font-size: 0.8rem;
  margin-bottom: 4px;
  padding: 2px;
}

.card-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-footer {
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  padding: 1rem 1.5rem;
}

/* Dashboard and Menu Cards */
.jumbotronCards {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 1rem;
  padding: 2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.list-group-item,
.list-group-itemDash {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding: 0.25rem .5rem;
  border: .5px solid #ced4da;
}

.report-title-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.info-icon {
  /* margin-left: 8px;  */
  padding-right: 20px;
}

.report-checkbox {
  padding-right: 57px;
}

/* Button Styling */
.btnEU {
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.btn-warning,
.btn-success,
.btn-danger {
  color: #ffffff;
}

.btn-warning {
  background-color: #ffc107;
}

.btn-success {
  background-color: #28a745;
}

.btn-danger {
  background-color: #dc3545;
}

.btnEU:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btnEU:hover.btn-success {
  background-color: #218838;
  color: black;
}

.btnEU:hover.btn-danger {
  background-color: #c82333;
  color: black;
}

/* Utility and Responsive Styles */
.clickable {
  cursor: pointer;
}

#navContainer .nav-ul li.unpublished a {
  color: #ffc107 !important; /* Bootstrap warning yellow color */
  opacity: 0.8; /* Adjust opacity as needed */
  font-style: italic; /* Keep italic style for distinction */
}

.list-group-itemDash.unpublished {
  color: #ffc107 !important; /* Bootstrap warning yellow color */
  opacity: 0.8; /* Adjust opacity as needed */
  font-style: italic; /* Keep italic style for distinction */
}

@media (max-width: 768px) {
  .jumbotronCards {
    grid-template-columns: 1fr;
  }

  /* Ensure that list items stack without added space on smaller screens */
  .list-group-item,
  .list-group-itemDash {
    margin-bottom: 0;
  }
}
