.modal-body { 
 color: black; 
}

.modal-header { 
 color: black; 
 background-color: var(--color-secondary)
}

.modal-button { 
 color: var(--color-tertiary); 
}
