.merged-files__table-responsive {
  margin: 20px;
  margin-bottom: 100px;
}

.merged-files__card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.merged-files__card-header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  font-size: 1.3em;
  font-weight: bold;
}

.merged-files__card-body {
  padding: 14px;
}

.merged-files__card-text {
  margin-bottom: 14px;
}

.merged-files__export-btn {
  display: inline-block;
  margin-top: 10px;
}

.merged-files__export-btn--disabled {
  display: inline-block;
  margin-top: 10px;
}

/* Prevent button from growing to 100% width */
.merged-files__export-btn,
.merged-files__export-btn--disabled {
  width: auto;
}

.merged-files__export-btn--disabled {
  cursor: not-allowed;
}

.primary-color {
  color: #007bff;
}

.secondary-color {
  color: #6c757d;
}

.success-color {
  color: #28a745;
}

.danger-color {
  color: #dc3545;
}

.warning-color {
  color: #ffc107;
}

.info-color {
  color: #17a2b8;
}

.light-color {
  color: #f8f9fa;
}

.dark-color {
  color: #343a40;
}
