.table-container {
  width: 100%; 
  background-color: #f8f9fa;
  padding: 10px;
}

.data-table {
  width: 100%; 
  border-collapse: collapse;
  margin: 0 auto; 
  /* table-layout: fixed;  */
}

.reportCard {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.comment-box-cell {
  flex: 2;
  min-width: 200px;
}

.comment-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  font-size: 1rem;
  resize: vertical;
  overflow: hidden;
  min-width: 100px;
}

.comment-div {
  display: flex;
  width: 100%;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
}

.button-container > button {
  margin-right: 3px;
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0; 
  width: 100%;
  padding: 0 10px;
}

.record-count {
  font-size: 18px;
  color: #555;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;
}

.data-table-title {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
  word-wrap: break-word; 
  white-space: normal;
  overflow-wrap: break-word; 
  max-width: 100%; 
}

.search-input-report {
  width: 35%;
  height: 40px;
  border: 1px solid #cecece;
  font-size: 1.25rem;
  font-weight: 500;
  margin-left: auto;
}

.column-header {
  text-align: center;
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 768px) {
  .data-table-title {
    font-size: 24px;
  }
  
  .search-input-report {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .data-table-title {
    font-size: 20px;
  }
  
  .search-input-report {
    width: 70%;
  }
}

@media screen and (max-width: 400px) {
  .data-table-title {
    font-size: 16px;
  }
  
  .search-input-report {
    width: 80%;
  }
}

@media screen and (max-width: 300px) {
  .data-table-title {
    font-size: 12px;
  }
  
  .search-input-report {
    width: 90%;
  }
}

@media print {
  @page {
    size: landscape;
  }

  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .table-container {
    width: 100%;
    overflow: visible;
  }

  .data-table {
    table-layout: auto;
  }

  .data-table-title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
  }

  .column-header {
    font-size: 1rem;
    font-weight: 700;
  }

  .comment-div {
    display: flex;
    width: 100%;
  }
}

