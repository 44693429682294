.pointer {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

/* Header Section */
.header-container h2 {
  color: white;
  margin-left: 20px;
  font-size: 3rem;
}

/* Admin Table Styling */
.admin-table-container {
  padding: 20px;
  margin-bottom: 10px;
}

.reports-table-container {
  padding: 10px;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
}

.admin-table th,
.admin-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.admin-table th {
  background-color: #f2f2f2;
}

.admin-table td button {
  margin-right: 4px;
}

/* Grid Layouts */
.icon-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
}

.columnBoxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

/* Icon Styling */
.icon-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.icon-item:hover {
  background-color: #f0f0f0;
}

/* Edit Menu Container */
.edit-menu-container {
  padding: 20px;
  margin-bottom: 10px;
}

/* Footer Styling */
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

/* Button Spacing */
.nowrap button {
  margin-right: 5px;
}

.me-2-column {
  margin-right: 5px;
  color: white;
  background-color: black;
  border-right: solid 1px black;
  border-left: solid 1px black;
  padding: 1px;
}

/* General styling for modal */
.sql-modal {
  max-width: 80vw;
  width: 100%;
}

.sql-query-wrapper {
  background-color: #d7d7d7;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  height: 70vh;
  overflow-y: auto;
  width: 100%;
}

.sql-query-editor {
  margin-bottom: 20px;
  height: fit-content;
}

#custom-query-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.sql-query-results {
  margin-top: 40px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sql-query-results table {
  border-radius: 5px;
  overflow: hidden;
}

#custom-query-button {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: white;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin-top: 10px;
}

#custom-query-button:hover {
  background-color: #007bff;
  border-color: #007bff;
}

.sql-modal .dropdown-toggle {
  padding: 10px 20px;
  margin-left: auto;
  border-radius: 5px;
}

.custom-modal-width .modal-dialog {
  max-width: 85%;
  max-height: 90%;
}

.code {
  font-family: "Courier New", Courier, monospace;
}

/* Tab Title Container */
.tab-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}

/* Tab Title Input Styling */
.tab-title-input {
  width: auto;
  min-width: 100px; 
  max-width: 250px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
  background: transparent;
  text-align: center;
  font-weight: bold;
}

