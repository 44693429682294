:root {
  --primary-color: #007bff;
  --success-color: #28a745;
  --danger-color: red;
  --info-color: #33a1fd;
  --hover-darken-factor: 0.1;
  --background-light: #f8f9fa;
  --text-color: #495057;
  --border-color: #dee2e6;
}

.umFluid {
  padding: 0 15px;
  margin-bottom: 100px;
}

.card-um {
  margin-bottom: 20px;
  background-color: var(--background-light);
  border-radius: 0.25rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transition: width 0.3s ease;
  width: 100%;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.666667%;
}

.col-9 {
  width: 75%;
}

.userManagement-btns {
  display: flex;
  gap: 15px;
  border-radius: 0.5rem;
  padding: 10px;
  margin: 10px;
}

.umicon {
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.export-button {
  background-color: #FFA500 !important; 
  color: white !important;
  border: none !important;
}

.remove-ad-user-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.disabled-button {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}

.user-name {
  text-decoration: none;
  padding-left: 5px;
}

.user-name-link {
  text-decoration: underline;
  color: inherit;
  padding-left: 5px;
  cursor: pointer;
}

.non-clickable-link.user-name {
  text-decoration: underline;
  cursor: default;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  background-color: rgb(239, 231, 231);
  overflow: visible;
}

.rdt_Table {
  width: 100% !important;
  table-layout: auto !important;
}

.rdt_TableHeadRow {
  background-color: var(--background-light);
  white-space: normal !important;
}

.rdt_TableHeadRow > div {
  display: table-cell;
  white-space: normal;
  word-wrap: break-word;
  padding: 8px 12px; /* Adjusted padding */
  font-size: 13px; /* Reduced font size */
}

.authText {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  padding: 10px;
}

.search-and-toggle-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.search-bar-user {
  flex: 1;
}

.toggle-columns-button {
  padding: 0.375rem 0.75rem;
  font-size: 1.25em;
  margin-bottom: 10px;
}

#accessToggle {
  text-decoration: none !important;
  color: #0056b3;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  padding: 0.2em;
  cursor: pointer;
}

.addNewUserTxt {
  color: white;
  padding: 2px;
}

.umNameHeader {
  margin: auto;
  white-space: normal;
  word-wrap: break-word;
}

/* Legend Styling */
.list-group-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 1.25rem;
  background-color: var(--background-light);
  color: var(--text-color);
  border-radius: 0.25rem;
  font-size: 16px;
  margin: 5px 0;
}

.list-group-item svg {
  margin-right: 10px;
}

.table-legend {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
}

/* Responsive Adjustments */
@media only screen and (max-width: 1600px) {
  /* Adjustments if needed */
}

@media only screen and (max-width: 1200px) {
  .search-bar-user {
    width: 100%;
  }

  .table-wrapper {
    padding: 5px;
  }

  .authText {
    font-size: 20px;
  }

  .userManagement-btns {
    flex-direction: column;
    align-items: stretch;
  }
}

@media (max-width: 768px) {
  .authText {
    font-size: 18px;
  }

  .userManagement-btns {
    flex-direction: column;
    align-items: stretch;
  }

  .toggle-columns-button {
    font-size: 1em;
  }

  .list-group-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .user-name,
  .user-name-link {
    padding-left: 0;
  }
}

@media (max-width: 576px) {
  .authText {
    font-size: 16px;
  }

  .search-and-toggle-wrapper {
    flex-direction: column;
    align-items: stretch;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
