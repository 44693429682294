.release-notes-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.table-wrapper {
  margin-top: 5px;
  overflow-x: auto;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}