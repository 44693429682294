.help-container {
  /* Font sizing */
  --title-font-size: 38px;
  --subtitle-font-size: 24px;
  --heading-font-size: 20px;
  --paragraph-font-size: 18px;
  --list-font-size: 16px;
  
  /* Spacing */
  --base-spacing: 20px;
  --small-spacing: 10px;
}

.help-paragraphWel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-container {
  border: 1px solid var(--color-secondary);
  border-radius: 0.25rem;
  padding: var(--base-spacing);
  margin-top: var(--base-spacing);
  margin-bottom : var(--base-spacing);
  background-color: var(--color-primary);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.help-card {
  border: 1px solid var(--color-secondary);
  border-radius: 0.25rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin: var(--base-spacing) auto;
  padding: var(--small-spacing);
  background-color: var(--color-primary);
  width: 95%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.help-card-body {
  padding: var(--base-spacing);
}

.help-title {
  border-bottom: 2px solid var(--color-tertiary);
  padding-bottom: var(--small-spacing);
  font-size: var(--title-font-size);
  font-weight: 600;
  margin-bottom: var(--base-spacing);
  /* color: var(--color-neutral); */
}

.help-subtitle {
  font-size: var(--subtitle-font-size);
  font-weight: 600;
  margin-bottom: var(--small-spacing);
  color: var(--color-neutral);
}

.help-heading {
  font-size: var(--heading-font-size);
  font-weight: 600;
  margin-bottom: var(--small-spacing);
  color: var(--color-neutral);
}

.help-paragraph {
  font-size: var(--paragraph-font-size);
  line-height: 1.6;
}

.help-list {
  font-size: var(--list-font-size);
  line-height: 1.6;
}

.help-list-item {
  margin-bottom: var(--small-spacing);
}

.newUserSteps {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

/* Utility Classes */
.center-img {
  display: block;
  margin: 0 auto;
}

.full-width-img {
  width: 100%;
  height: auto;
}

.medium-width-img {
  width: 60%;
  height: auto;
}

.small-width-img {
  width: 40%;
  height: auto;
}

.large-width-img {
  width: 80%;
  height: auto;
}

.doubleCard {
  display: flex;
  justify-content: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .help-card {
    width: 100%;
  }

  .help-title {
    font-size: calc(var(--title-font-size) - 8px);
  }

  .help-subtitle {
    font-size: calc(var(--subtitle-font-size) - 4px);
  }

  .help-heading {
    font-size: calc(var(--heading-font-size) - 2px);
  }

  .help-paragraph,
  .help-list,
  .help-list-item {
    font-size: calc(var(--paragraph-font-size) - 2px);
  }

  .newUserSteps {
    flex-direction: column;
    gap: var(--base-spacing);
  }
}
