/* Global styles */
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 18px;
}

.table-responsive {
  width: 99%;
  height: 100%;
  padding-top: 10px;
  overflow-x: auto;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.main-content-container {
  z-index: 1;
  height: 90vh;
  width: 100%;
  overflow-x: auto;
  position: relative;
  margin: 0;
  padding: 5px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #29c8f0;
  width: 100%;
  padding: 2px 10px;
}

.header-container.right-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}

.header-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.header-navbar .navbar-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.card-text,
h5.card-title {
  color: black;
}
.card {
  margin-left: 2em;
}

a.dropdown-item {
  display: block;
  text-align: center;
  color: black;
}

.dropdown-menu.show {
  overflow: auto;
  max-height: 80vh;
}

a.nav-link {
  color: #0985a4;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  transition: background-color 0.3s ease;
  overflow-y: auto;
}
#root > div > div.header-container > div:nth-child(1) > div:nth-child(2) > nav {
  background-color: white;
  border-radius: 10px;
  z-index: 12;
}
a.nav-link:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: #262626;
}
.navbar-toggler,
.card {
  border: none !important;
}
.col-2.collapsed {
  width: 5%; /* Adjust as needed */
  height: auto;
}

.col-10.expanded {
  width: 100%; /* Adjust as needed */
}

.navbar-light .navbar-nav .nav-link {
  color: #0985a4;
  letter-spacing: 0.3px;
}

#root > div > div.header-container > div:nth-child(1),
#root > div > div.header-container > div:nth-child(2) {
  padding: 0;
}
#root > div > div.header-container > div:nth-child(1) {
  display: flex;
  width: calc(100% - 400px);
}
#root > div > div.header-container > div:nth-child(3) {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
}
#root > div > div.header-container > div:nth-child(1) > div:nth-child(1) {
  padding-left: 0;
}
.col-2 {
  border: 1px solid #d3d3d3;
  background-color: whitesmoke !important;
  width: 20% !important;
  min-width: 320px;
}
.main-content-container.col-10 {
  width: 80%;
}
.main-content-container.col-10,
.col-2 {
  padding: 0;
  background-color: #fff;
}

.col-1 {
  width: 2.5em !important;
  background-color: whitesmoke;
  border: 1px solid #d3d3d3;
}
.main-content-container.col-11 {
  background-color: #fff;
  width: calc(100% - 2.5em);
}
nav {
  background-color: white;
  border-radius: 10px;
  height: 3em;
  z-index: 12;
}
.main-content-container .dashboardSpinner {
  position: absolute;
  right: 50%;
  top: 20%;
}

/* Table Styles */
.table {
  width: 100%;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: 1px solid var(--color-tertiary);
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border: 1px solid var(--color-tertiary);
}

.table th {
  background-color: var(--color-tertiary);
  color: var(--color-primary);
}

.table tbody tr:nth-child(even) {
  background-color: var(--color-primary);
}

.table tbody tr:nth-child(odd) {
  background-color: var(--color-secondary);
  color: var(--color-primary);
}

@media only screen and (max-width: 1600px) {
  #root > div > div.header-container > div:nth-child(1) {
    width: calc(100% - 300px);
  }
  .col-2 {
    min-width: 340px !important;
  }
  .main-content-container.col-10 {
    width: calc(100% - 340px);
  }
  .col-sm-6 {
    width: 80% !important;
  }
  #root > div > div.header-container > div:nth-child(3) {
    min-width: 300px;
  }
}

@media only screen and (max-width: 1200px) {
  #root > div > div.header-container > div:nth-child(1) > div:nth-child(2) {
    height: 2.5em;
    padding: 0;
  }
  #dropContainer {
    padding: 0;
  }
  #root
    > div
    > div.header-container
    > div:nth-child(1)
    > div:nth-child(2)
    > nav {
    border-radius: 5px;
    height: 1.6em;
  }
  .navbar-toggler:focus {
    box-shadow: none;
    outline: none;
  }
  :focus-visible {
    outline: none;
  }
}

@media only screen and (max-width: 990px) {
  #root > div > div.header-container > div:nth-child(2) {
    justify-content: center;
  }
  nav {
    width: 3.3em;
  }
  .navbar-collapse {
    align-items: center;
    background-color: white;
    padding: 10px 30px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    margin-top: 5px;
    box-shadow: 1px 1px 5px lightgrey;
    min-height: 50vh;
    min-width: 300px;
  }

  #basic-navbar-nav
    > div
    > div.nav-dropdown.nav-item.show.dropdown
    > div
    > a:nth-child(1) {
    top: -0.9em !important;
  }
}

@media only screen and (max-width: 820px) {
  .card-body > div {
    padding: 0;
  }
  .col-sm-6 {
    width: 100% !important;
  }
}

/* New Media Queries for Main Content Container */
@media only screen and (max-width: 1024px) {
  .main-content-container {
    height: 95vh;
    padding: 10px;
    overflow-x: scroll; /* Ensure x-axis scroll */
    width: calc(100% - 320px); /* Adjust width to account for nav */
  }
  .col-2 {
    min-width: 320px !important;
  }
}

@media only screen and (max-width: 768px) {
  .main-content-container {
    height: 95vh;
    padding: 15px;
    overflow-x: scroll; /* Ensure x-axis scroll */
    width: calc(100% - 300px); /* Adjust width to account for nav */
  }
  .col-2 {
    min-width: 300px !important;
  }
}

@media only screen and (max-width: 576px) {
  .main-content-container {
    height: 95vh;
    padding: 20px;
    overflow-x: scroll; /* Ensure x-axis scroll */
    width: calc(100% - 280px); /* Adjust width to account for nav */
  }
  .col-2 {
    min-width: 280px !important;
  }
}

@media only screen and (max-width: 480px) {
  .main-content-container {
    height: 95vh;
    padding: 25px;
    overflow-x: scroll; /* Ensure x-axis scroll */
    width: calc(100% - 250px); /* Adjust width to account for nav */
  }
  .col-2 {
    min-width: 250px !important;
  }
}
