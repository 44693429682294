.password-input {
  display: flex;
  align-items: center;
  position: relative;
}

.form-control {
  flex-grow: 1;
}

.password-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.form-control::-ms-reveal,
.form-control::-ms-clear {
  display: none;
}
