body {
  margin: 0;
  padding: 0;
  margin-right: 20px;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-primary: #ffffff;
  --color-secondary: #e2dadb;
  --color-tertiary: #9a9496;
  --color-neutral: #00acd7;
  --color-highlight: #86cd82;
}
