/* Style for the password expiration alert */
.expired-password-alert {
    font-weight: bolder;
    color: #dc3545; /* Adjust the color as needed */
    margin-bottom: 10px;
  }
  
  /* Style for the Active Directory warning */
  .ad-warning {
    background-color: yellow;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  /* General modal body styling, adjust as needed */
  .modal-body {
    font-size: 16px; /* Adjust font size as needed */
  }
  
  /* Styles for form-groupCP, if you need to adjust */
  .form-groupCP {
    margin-bottom: 15px;
  }
  
  /* Style for alert messages */
  .alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  